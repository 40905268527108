<template>
  <el-config-provider :button="{autoInsertSpace: true}">
    <Layout />
</el-config-provider>
</template>

<script>
import Layout from './views/MainLayout.vue';

export default {
  name: 'App',
  components: {
    Layout
  }
}
</script>

<style>
#app {
  font-family: Inter,Helvetica Neue,Helvetica,'PingFang SC','Hiragino Sans GB','Microsoft YaHei','微软雅黑',Arial,sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
