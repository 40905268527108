import { createRouter, createWebHashHistory } from 'vue-router';

const routes = [
  // {
  //   path: '/',
  //   name: 'Home',
  //   component: () => import('../views/Index.vue')
  // },
  {
    path: '/',
    name: 'Home',
    component: () => import('../components/CarLoanCalculator.vue')
  },
  {
    path: '/clc',
    name: 'CarLoanCalculator',
    component: () => import('../components/CarLoanCalculator.vue')
  },
  {
    path: '/clc2',
    name: 'CarLoanCalculator2',
    component: () => import('../components/CarLoanCalculator2.vue')
  },
  {
    path: '/clc3',
    name: 'CarLoanCalculator3',
    component: () => import('../components/CarLoanCalculator3.vue')
  }
];

const router = createRouter({
  history: createWebHashHistory(),
  routes
});

export default router;